<template>
<span class="img_bgc">
  <template v-if="imgType == 'star'">
    <img class="img_xing"
         :class="isBanlance?'bounceAnimate':''"
         :style="{height:size+'px'}"
         v-if="!ori_collect"
         src="@/assets/images/nocheckStar.png"
         @click.stop="CollectNow(id)"
         alt=""
    />
  <img class="img_xing"
       :class="isBanlance?'bounceAnimate':''"
       :style="{height:size+'px'}"
       v-else
       src="@/assets/images/checkStar.png"
       @click.stop="CollectNow(id)"
       alt=""
  />
  </template>
  <template v-else>
    <img class="img_xin"
         :class="isBanlance?'bounceAnimate':''"
         :style="{height:size+'px'}"
         v-if="!ori_collect" src="@/assets/images/nocheck_favorite.png"
         @click.stop="CollectNow(id)"
         alt=""
    />
  <img class="img_xin"
       :class="isBanlance?'bounceAnimate':''"
       :style="{height:size+'px'}"
       v-else
       src="@/assets/images/check_favorite.png"
       @click.stop="CollectNow(id)" alt=""
  />
  </template>
</span>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isBanlance: false
    };
  },
  props: {
    id: {
      type: String,
      default: () => {
        return "";
      },
    },
    iconType: {
      type: String,
      default: () => {
        return "heart";
      },
    },
    lightColor: {
      type: String,
      default: () => {
        return '#ff3c00';
      }
    },
    size: {
      type: Number,
      default: () => {
        return 12;
      },
    },
    /*
    * star ⭐
    * xin  ♥
    * */
    imgType: {
      type: String,
      default: () => 'star'
    }
  },
  methods: {
    CollectNow(id) {
      this.isBanlance = true;
      setTimeout(() => {
        this.isBanlance = false;
      }, 1500);
      if (this.ori_collect) {
        this.$store.dispatch('appCenter/reduceFavoriteMethods', id);
      } else {
        let obj = {
          type: 3,
          appId: id
        }
        this.$store.dispatch('appCenter/addFavoriteMethods', obj);
      }
    }
  },
  computed: {
    ori_collect() {
      return this.$store.getters['appCenter/getFavorites'].indexOf(this.id) > -1;
    }
  },
};
</script>
<style lang="scss" scoped>
.img_xing {
  vertical-align: initial;
}
</style>
