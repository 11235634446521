import Vue from 'vue';
import {vuexOidcCreateRouterMiddleware} from 'vuex-oidc';
import VueRouter from 'vue-router';
import store from "../store";
import {publicMenthods} from "@/mixins/utils";
import gql from "@/graphql/builtin";
import '@/apolloConfig.js';
import {oidcCallback, signError, E404, noAuth, workbench, commondetail} from "@/router/defaultRouter";
import routerList from "@/router/routerList";

Vue.use(VueRouter);

let terminal = 'pc';
if (publicMenthods.getMobile()) terminal = 'wechat';

/*
* 处理重复跳转当前路由报错问题
* */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
    mode: 'history',
    base: window.commonApi.PATH ? window.commonApi.PATH : '',
    routes: [
        {...routerList},
        oidcCallback,
        signError,
        E404,
        noAuth,
        workbench,
        commondetail
    ]
})

/*
* 数据 children 展开
* */
function handleRouter(data, arr = []) {
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.children && item.children.length > 0) {
            handleRouter(item.children, arr);
        } else {
            arr.push(item);
        }
    }
    return arr;
}

/*
* 数据转树形处理
* */
function handleData(data, pid) {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item.parent == pid) {
            let children = handleData(data, item.name);
            if (children.length > 0) item.children = children;
            arr.push(item);
        }
    }
    return arr;
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title;
    var notAuth = to.matched.some(record => record.meta.isPublic);
    if (notAuth) {
        next();
    } else {
        let middle = vuexOidcCreateRouterMiddleware(store, "oidcStore"), trends = [];
        middle(to, from, async () => {
            if (store.state.canvasmenusList && store.state.canvasmenusList.length == 0) {
                await apolloProvider.clients.builtin.query({
                    fetchPolicy: "no-cache",
                    client: 'builtin',
                    query: gql.canvasmenus,
                }).then(res => {
                    let menusList = res.data.canvasmenus && res.data.canvasmenus.length > 0 ? res.data.canvasmenus : [];
                    store.commit('updateDefaultMenusList', menusList);
                    store.commit('updateCanvasmenusList', handleData(menusList));
                }).catch(error => {
                    console.log(error);
                    router.push('/signin-oidc-error');
                })
            }
            setTimeout(() => {
                /** 判断当前跳转的路由是否是有效地址 **/
                let newMenusList = store.state.defaultmenusList.filter(item => to.path == item.uri.replace('vue://', '').replace('@', '')),
                    newDefaultRouter = handleRouter(router.options.routes[0].children, []).filter(item => to.path == item.path);
                if (newMenusList.length > 0 && newDefaultRouter.length > 0) next();
                else if (newMenusList.length == 0 && newDefaultRouter.length > 0) router.push('/no-auth');
                else router.push('/404');
            }, 100);
        })
    }
})

/*
* 路由菜单整理
* */
// function trendsMenuList(trendsList) {
//     let newTrendsList = [];
//     for (let router of trendsList) {
//         let newUri = router.uri.replace('vue://', '').replace('@', '');
//         let node = {
//             path: newUri,
//             name: router.description,
//             component: () => import(`@/views/${terminal}${newUri}`),
//             meta: {
//                 title: router.text
//             }
//         }
//         if (router.children && router.children.length) {
//             node.children = trendsMenuList(router.children);
//         }
//         newTrendsList.push(node);
//     }
//     return newTrendsList;
// }
export default router;
