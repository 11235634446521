import gql from "@/graphql/scheduleCenter"
import {publicMenthods} from "@/mixins/utils"
import {Message} from 'element-ui'

const state = {
    scheduleLoading: false,
    scheduleArr: [],  // 日程列表
    schoolCalendarConfig: [],  // 校历设置列表
    semesterList: [],   // 学期列表
    findCurrentTemp: {}, // 日程详情
    isLoadingDone: false,  // 日程详情加载
}
const getters = {
    getScheduleList: () => state.scheduleArr,
    getSchoolCalendarConfig: () => state.schoolCalendarConfig,
    getSemesterList: () => state.semesterList,
    getFindTempDetailList: () => state.findCurrentTemp,
}

const actions = {
    // 获取日程
    async getScheduleListFun({state, commit, dispatch}, filter) {
        commit('updateScheduleLoadingStatus', true);
        await apolloProvider.clients.calendar.query({
            client: 'calendar',
            fetchPolicy: 'no-cache',
            query: gql.userSchedules,
            variables: {
                filter: filter
            }
        }).then(res => {
            if (res.data.userSchedules) {
                state.scheduleArr = res.data.userSchedules;
                commit('updateScheduleLoadingStatus', false);
            }
        }).catch(error => {
            console.log(error);
            commit('updateScheduleLoadingStatus', false);
        })
    },
    // 获取学期
    getSemesterList({state, commit, dispatch}) {
        apolloProvider.clients.calendar.query({
            client: 'calendar',
            query: gql.schoolCalendar,
            variables: {}
        }).then(res => {
            if (res.data.schoolCalendar) {
                state.semesterList = res.data.schoolCalendar;
            }
        }).catch(error => {
            console.log(error);
        })
    },
    // 获取校历设置
    getSchoolCalendarConfigService({state, commit, dispatch}) {
        apolloProvider.clients.calendar.query({
            client: 'calendar',
            query: gql.schoolCalendarDay,
            variables: {}
        }).then(res => {
            if (res.data.schoolCalendarDay) {
                state.schoolCalendarConfig = res.data.schoolCalendarDay;
            }
        }).catch(error => {
            console.log(error);
        })
    },
    // 查看日程详情
    async findTempDetail({state, commit, dispatch}, id) {
        commit('updateLodingStatus', true);
        await apolloProvider.clients.calendar.query({
            client: 'calendar',
            query: gql.schedule,
            variables: {
                id: id
            }
        }).then(res => {
            if (res.data.schedule) {
                state.findCurrentTemp = res.data.schedule;
            }
            commit('updateLodingStatus', false);
        }).catch(error => {
            console.log(error);
        })
    },
    // 添加日程
    addWorkSchedule({state, commit, dispatch}, {form, type}) {
        apolloProvider.clients.calendar.mutate({
            client: 'calendar',
            mutation: gql.saveSchedule,
            variables: {
                entity: {...form}
            }
        }).then(res => {
            if (res.data.saveSchedule.status) {
                dispatch('getScheduleListFun');
                if (type == 'add') {
                    Message.success('新增日程成功！');
                } else {
                    Message.success('编辑日程成功！');
                }
            }

        }).catch(error => {
            if (type == 'add') {
                Message.error('新增日程失败！');
            } else {
                Message.error('编辑日程失败！');
            }
            console.log(error);
        })
    },
    // 删除日程
    async deleteTempDetail({state, commit, dispatch}, id) {
        await apolloProvider.clients.calendar.mutate({
            client: 'calendar',
            mutation: gql.deleteSchedule,
            variables: {
                id: id
            }
        }).then(res => {
            if (res.data.deleteSchedule) {
                dispatch('getScheduleListFun');
                Message.success('删除日程成功！');
            } else {
                Message.error('删除日程失败！');
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

const mutations = {
    updateLodingStatus(state, lodingStatus) {
        state.isLoadingDone = lodingStatus;
    },
    updateScheduleLoadingStatus(state, lodingStatus) {
        state.scheduleLoading = lodingStatus;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}