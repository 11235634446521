//生产环境走window.__env
// window.__env={
//   TASKCENTER_URL:"http://devel.ketanyun.cn/taskcenter",
//   KNOWLEDGE_URL:"http://devel.ketanyun.cn",
//   TASKAPI_URL:"http://devel.ketanyun.cn/taskapi",
//   INFOPLUS_URL:"http://devel.ketanyun.cn/infoplus/apis/v2",
//   SSO_URL:"http://devel.ketanyun.cn/sso",
//   SSO_SCOPE:"profile+task+process+app",
//   SSO_KEY:"SKvpw2Nm1ZOSifdDeNUk",
// }
if (window.__env) {
    window.commonApi = window.__env;
    //先用origin
    window.commonApi.KNOWLEDGE_BOT_URL = location.origin;
    // window.commonApi.BUS_URL = window.commonApi.BUS_BASE_URL + "/workspace";
    window.commonApi.SSO_SCOPE = "app openid profile task process submit data profile_edit";

    // "app app_template app_edit openid profile task process notification_query notification_mark openid submit";

} else {
    // var origin = "http://t1.saas.ketanyun.cn";
    // var key = "9LMXKQ8i8ou7vdOYbbZR"; // t1
    var origin = "https://cloud.ketanyun.cn";
    var key = "yRhVALYSZYAX7qb89CTW"; // cloud
    // var key = "8cmcpSnWQq6t4zkP4Bxu"; // cloud
    // var origin = "http://t2.saas.ketanyun.cn";
    // var key = "Ysek11ek30C8IX4Op8hB"; // t2
    window.commonApi = {
        INFOPLUS_URL: "",
        INFOPLUS_API: "",
        INFOPLUS_BASE_URL: "",
        OAUTH_AUTHORITY_URL: origin + "/sso/oauth2",
        SSO_LOGOUT_URL: origin + "/sso/logout",
        SSO_KEY: key,
        PATH: "/workspace",
        VERSION: "20220824",
        BUS_BASE_URL: "/bus/graphql",
        NEWS_URL: "/bus/graphql/news",
        NEWS_BASE_URL: "/bus/graphql/news",
        TASKAPI_URL: "/bus/graphql/taskapi",
        APPCENTER_URL: "/bus/graphql/appcenter",
        NOTIFICATION_URL: "/bus/graphql/notification_v2",
        IAM_URL: "/bus/graphql/iam",
        BUS_URL: "/bus/graphql/builtin",
        OTHER_URL: "ws://127.0.0.1:8080/api",
        TRACE_URL: "/bus/graphql/trace",
        CALENDAR_URL: "/bus/graphql/calendar",
        SSO_SCOPE: "app openid profile task process submit data profile_edit",
        WORKSPACE_URL: "/bus/graphql/workspace"
    }
}

let redirect_uri = encodeURIComponent(window.location.href);
let client_id = window.commonApi.SSO_KEY;
let scope = window.commonApi.SSO_SCOPE;
let authority = window.commonApi.OAUTH_AUTHORITY_URL;
export const oidcSettings = {
    authority: authority,
    metadata: {
        issuer: authority,
        authorization_endpoint: authority + "/authorize",
        userinfo_endpoint: authority + "/userinfo",
        end_session_endpoint: window.commonApi.SSO_LOGOUT_URL + "?redirect_uri=" + redirect_uri,
        jwks_uri: authority + "/jwks.json"
    },
    clientId: client_id,
    redirectUri: window.location.origin + window.commonApi.PATH + "/oidcCallback",
    responseType: "id_token token",
    scope: scope
};

function getMobile() {
    var flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag === null) {
        return false;
    } else {
        return true;
    }
}

// 移动端 静默登录
if (getMobile()) {
    oidcSettings.automaticSilentRenew = true;
    oidcSettings.silentRedirectUri = window.location.origin + window.commonApi.PATH + "/oidcSilent";
    oidcSettings.silentRequestTimeout = 3 * 1000;
}
