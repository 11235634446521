var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myHeader"},[_c('div',{staticClass:"myLogo"},[_c('img',{attrs:{"src":_vm.userConfig && _vm.userConfig.systemLogoImageUrl,"alt":""},on:{"click":_vm.reload}}),_c('span',[_vm._v(_vm._s(_vm.userConfig && _vm.userConfig.systemName))])]),_c('el-input',{staticClass:"mySearchInput",attrs:{"placeholder":"Search for...","prefix-icon":"el-icon-search","size":"small"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('ul',{staticClass:"myInfo"},[_c('el-popover',{attrs:{"placement":"bottom","width":350,"trigger":"hover","popper-class":"popover_readDialog"}},[(_vm.getAllUnreadNowInfos.length > 0)?[_c('div',{staticClass:"popTop"},[_c('span',{staticClass:"titleContent"},[_vm._v("未读（"+_vm._s(_vm.getAllUnreadNowInfos.length)+"）")]),_c('span',{staticClass:"titleDoat",on:{"click":function($event){return _vm.toRead(_vm.getAllUnreadNowInfos, 'all')}}},[_vm._v("全部标为已读")])]),_c('ul',{staticClass:"popBottom"},_vm._l((_vm.getAllUnreadNowInfos),function(nowInfo,noInfoIndex){return _c('li',{key:noInfoIndex,staticClass:"popBottomLi"},[_c('p',{staticClass:"titleArea"},[_c('img',{staticClass:"img_svg",attrs:{"src":require("@/assets/images/svg/doatRead.svg"),"alt":""}}),_c('span',{staticClass:"title overflowEllipsis",attrs:{"title":nowInfo.node.name},on:{"click":function($event){_vm.pushUrl(
                    _vm.isJSON(nowInfo.node.content)
                      ? _vm.filterVal(nowInfo.node.content, 'url')
                      : ''
                  )}}},[_vm._v(" "+_vm._s(nowInfo.node.name ? nowInfo.node.name : "无标题")+" ")]),_c('el-popover',{attrs:{"placement":"top","trigger":"hover","popper-class":"popover_small"}},[_c('span',{staticClass:"areadyRead"},[_vm._v("标记已读")]),_c('span',{staticClass:"doat",attrs:{"slot":"reference"},on:{"click":function($event){$event.stopPropagation();return _vm.toRead(nowInfo, 'one')}},slot:"reference"})])],1),_c('p',{staticClass:"content overflowEllipsis_2",domProps:{"innerHTML":_vm._s(
                _vm.isJSON(nowInfo.node.content)
                  ? _vm.changeMarkFromHtml(_vm.filterVal(nowInfo.node.content, 'des'))
                  : _vm.changeMarkFromHtml(nowInfo.node.content)
              )}}),_c('p',{staticClass:"time"},[_vm._v(_vm._s(nowInfo.node.createTime))])])}),0)]:[_c('div',{staticClass:"noneDate"},[_vm._v("暂无待阅的消息")])],_c('li',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-badge',{staticClass:"badgeItem",attrs:{"type":"danger","value":_vm.getAllUnreadNowInfos.length,"max":99,"hidden":_vm.getAllUnreadNowInfos.length == 0}},[_c('i',{staticClass:"el-icon-bell"})])],1)],2),_c('li',[_c('el-dropdown',{staticClass:"loginInfo",on:{"command":_vm.infoCommand}},[_c('div',[_c('span',{staticClass:"logo_name"},[_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.name ? _vm.userInfo.name.slice(0, 1) : ""))]),_vm._v(" 您好，"+_vm._s(_vm.userInfo && _vm.userInfo.name ? _vm.userInfo.name : "")),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":'safeSetting'}},[_vm._v("安全设置")]),_c('el-dropdown-item',{attrs:{"command":'identity'}},[_vm._v("身份信息")]),_c('el-dropdown-item',{attrs:{"command":'logout'}},[_vm._v("注销登录")])],1)],1)],1)],1),_c('div',{staticClass:"themeToggle"},[_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" "+_vm._s(_vm.selectTheme.text)),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.themeOptions),function(theme,idx){return _c('el-dropdown-item',{key:idx,attrs:{"command":theme}},[_vm._v(_vm._s(theme.text)+"版")])}),1)],1)],1),_c('IdentityCard',{attrs:{"showMesCard":_vm.showMesCard,"positionMes":_vm.getPosition},on:{"closeCard":_vm.closeCard}}),(_vm.isShowSearchFlag)?_c('GlobalSearch',{attrs:{"searchText":_vm.searchText,"leftX":_vm.calculateOffsetX}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }