<template>
  <el-drawer size="100%" direction="rtl" :visible.sync="showMesCard" :before-close="handleClose" :append-to-body="true"
             :modal-append-to-body="false">
    <div class="people_mes">
      <img src="@/assets/images/user_icon.png" alt="">
      <el-descriptions :title="positionMes.name">
        <el-descriptions-item label="用户名">{{ positionMes.username }}</el-descriptions-item>
        <el-descriptions-item label="证件号码">{{ positionMes.cardNo }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ positionMes.phone }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ positionMes.email }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="position_mes">
      <div class="position_left">
        <el-card v-for="item in positionMes.positions" :key="item.id">
          <div class="position_div" :class="chooseItem == item? 'active' : ''" @click.stop="choosePosition(item)">
            <div class="position_up">
              <h3 style="color: #9f2a3d;">{{ item.dept.name }}</h3>
              <p style="font-weight: bold">{{ item.post.name }}</p>
            </div>
            <div class="position_down">
              <div class="position_start">
                <span>身份开始时间:</span>
                <span v-if="item.startTime">{{ getLocalTime(item.startTime, 'YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div class="position_already">
            <span>已在任：<span v-if="item.startTime && item.startTime < new Date().getTime()">
              {{ getAgoCurrentTime(item.startTime) }}
            </span>
            </span>
              </div>
            </div>
            <img class="active_image" v-if="chooseItem == item" src="@/assets/images/card_active.png" alt=""/>
          </div>
        </el-card>
        <el-card>
          <div class="position_div" :class="chooseItem.dept.name == '其他'? 'active' : ''"
               @click.stop="chooseItem = {dept:{name:'其他'}}">
            <div class="position_up">
              <h3 style="color: #9f2a3d;">其他</h3>
            </div>
          </div>
        </el-card>
      </div>
      <div class="position_right">
        <h3 v-if="findRulesApps() && findRulesApps().length > 0">{{ chooseItem.dept.name }}</h3>
        <ul class="app_list" v-if="findRulesApps() && findRulesApps().length > 0">
          <li v-for="(item,index) in findRulesApps()" :key="index" @click="pushUri(item)">
            <img class="appImage" v-if="item.icon" :src="item.icon" @error="loadDefault"/>
            <img class="appImage" v-else :src="defaultImg"/>
            <p class="li-text overflow_hidden" :title="item.text"> {{ item.text }}</p>
            <p class="li-description overflow_hidden" :title="item.description"> {{ item.description }}</p>
          </li>
        </ul>
        <div class="none_data" v-else>
          <span>当前部门岗位下暂无应用！</span>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "identityCard",
  inject: ['getAgoCurrentTime', 'getLocalTime', 'toUri'],
  filters: {
    getTime(str) {
      return str.replace(/(.+?)\-(.+?)\-(.+)/, "$1年$2月$3日");
    }
  },
  data() {
    return {
      chooseItem: {},
      appLists: [],
      defaultImg: require('@/assets/images/application_center.png')
    }
  },
  props: {
    showMesCard: {
      type: Boolean,
      default: () => false
    },
    positionMes: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState("appCenter", ["appList"]),
    ...mapState(["userInfo"]),
    ...mapState("appCenter", ["loadingDataStart"])
  },
  watch: {
    positionMes: {
      deep: true,
      immediate: true,
      handler(oldV) {
        if (oldV && oldV.positions) {
          this.chooseItem = oldV.positions[0]; // 默认选中第一个
        }
      }
    },
    appList: {
      deep: true,
      immediate: true,
      handler(oldV) {
        if (oldV && oldV.length > 0) {
          this.appLists = oldV;
          this.findRulesApps();
        }
      }
    }
  },
  methods: {
    loadDefault(e) {
      e.target.src = this.defaultImg;
    },
    handleClose() {
      this.$emit('closeCard');
    },
    pushUri(item) {
      let obj = {type: 1, appId: item.id};
      this.$store.dispatch('appCenter/addFavoriteMethods', obj);
      this.toUri(item.uri);
    },
    choosePosition(item) {
      this.chooseItem = item;
      this.findRulesApps();
    },
    /*
    * @question 查询当前选中的分类中包含的app
    * @rules 部门code:岗位code
    * */
    findRulesApps() {
      let list = [], splitRules = '';
      if (this.chooseItem.dept.name == '其他') { // openid 不存在的放入其他
        splitRules = `::${this.userInfo.openid}`;
      } else {  // 部门code:岗位code匹配
        splitRules = `${this.chooseItem.dept.code}:${this.chooseItem.post.code}`;
      }
      this.appLists.forEach(item => {
        if (item.userFiltersMatched && item.userFiltersMatched.indexOf(splitRules) > -1) {
          list.push(item);
        }
        if (item.blocks) {
          item.blocks.forEach(item1 => {
            if (item1.userFiltersMatched && item1.userFiltersMatched.indexOf(splitRules) > -1) {
              list.push(item);
            }
          })
        }
      })
      return Array.from(new Set(list));
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow_hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .el-drawer {
  padding: 0 10px 0 20px;

  .el-drawer__header {
    line-height: 0;
    margin-bottom: 20px;
  }

  .el-drawer__body {
    padding: 0 15%;
  }
}

::v-deep .people_mes {
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 10px;
  border-bottom: 2px solid rgb(242, 242, 242);

  .el-descriptions {
    padding-left: 30px;

    .el-descriptions__header {
      margin-bottom: 0;

      .el-descriptions__title {
        height: 35px;
        line-height: 35px;
      }
    }

    &:not(.is-bordered) .el-descriptions-item__cell {
      display: block;
      padding-bottom: 3px;
    }

    .el-descriptions-item__label {
      font-size: 13px;
    }
  }
}

::v-deep .position_mes {
  height: 80%;
  display: flex;

  p {
    font-size: 14px;
  }

  span {
    font-size: 12px;
  }

  .position_left {
    width: 28%;
    height: 100%;
    padding: 15px 10px 10px 10px;
    overflow-y: scroll;

    .el-card {
      margin-bottom: 20px;
      border-radius: 0 !important;
      box-shadow: none !important;
      border: none !important;
      position: relative;

      .el-card__body {
        padding: 0;
        height: 160px;
      }

      .position_div {
        width: calc(100% - 23px);
        border-radius: 3px;
        cursor: pointer;
        border: 1.5px solid #fff;
        padding: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        margin: 10px 0 10px 10px;

        &.active {
          border: 1.5px solid #c92442;
        }

        &:hover {
          border: 1.5px solid #c92442;
        }

        .active_image {
          width: 15px;
          height: 15px;
          position: absolute;
          right: 0;
          top: calc((160px - 15px) / 2);
        }

        .position_up {
          border-bottom: 5px solid transparent;
          border-image: linear-gradient(to right, #b14d5f, #d52544) 1;

          h3, p {
            margin-top: 0 !important;
            margin-bottom: 1em !important;
          }
        }

        .position_down {
          display: flex;
          justify-content: space-between;
          padding-top: 15px;

          .position_start {
            span {
              display: block;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .position_right {
    padding: 30px 10px 20px 20px;
    width: 72%;
    height: 100%;
    overflow-y: scroll;

    h3 {
      color: #ae2c44;
      margin-bottom: 10px;
    }

    .app_list {
      li {
        width: 32%;
        display: inline-block;
        margin: 0 calc(3.999999% / 2) 10px 0;
        position: relative;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 8px 5px #ebebee;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .appImage {
          width: 20%;
          height: 80%;
          position: absolute;
          left: 10px;
          top: 10%;
        }

        p {
          display: inline-block;
          margin-left: 25%;
        }

        .li-text {
          width: 75%;
          height: 20px;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .li-description {
          width: 75%;
          height: 15px;
          font-size: 12px;
          color: #b5b5b5;
        }
      }
    }

    .none_data {
      line-height: 100px;
      text-align: center;

      span {
        font-size: 18px;
      }
    }
  }
}

/*
** 滚动条样式
*/
::v-deep ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

::v-deep ::-webkit-scrollbar {
  width: 2px;
}

::v-deep ::-webkit-scrollbar-thumb {
  background-color: #e7e6e6;
  border-radius: 8px;
}
</style>
<style>
.v-modal {
  /*z-index: 0 !important;*/
}
</style>