<template>
  <div class="date">
    <div class="month">
      <p>{{ getLocalTime(weekDays[0], 'YYYY年MM月DD日') }} - {{
          getLocalTime(weekDays[weekDays.length - 1], 'MM月DD日')
        }}</p>
      <p class="arrowTip">
        <span @click="weekPre">< 上一周</span>
        <span @click="weekNext">下一周 ></span>
      </p>
    </div>
    <ul class="weekdays">
      <li>周一</li>
      <li>周二</li>
      <li>周三</li>
      <li>周四</li>
      <li>周五</li>
      <li>周六</li>
      <li>周日</li>
    </ul>
    <ul class="days">
      <li v-for="(day, index) in weekDays" :key="index">
        <span class="chooseActive"
              v-if="day.getFullYear() == new Date().getFullYear()
                      && day.getMonth() == new Date().getMonth()
                      && day.getDate() == new Date().getDate()"
        >{{ day.getDate() }}
          </span>
        <span v-else>{{ day.getDate() }}</span>
        <ul class="contentMes">
          <li class="contentMes_li"
              v-for="(item,index) in getCurrentSchedule(day).midArr"
              :key="index"
              :style="{background: getCurrentSchedule(day).background}"
          >
            <p>{{ item.startTime }}~{{ item.endTime }}</p>
            <p class="text overflowEllipsis">{{ item.things }}</p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "weekCanlendr",
  inject: ['getLocalTime', 'getCurrentTimeParse'],
  data() {
    return {
      weekDays: [],
    }
  },
  created() {
    this.initData();
  },
  computed: {
    scheduleList() {
      return this.$store.getters['scheduleCenter/getScheduleList'];
    }
  },
  methods: {
    initData(cur) {
      let date = cur ? new Date(cur) : new Date();
      let getDay = date.getDay() == 0 ? 7 : date.getDay();
      let currentDate = this.getCurrentTimeParse(date, '{y}-{m}-{d}');
      this.weekDays = [];
      for (let i = getDay - 1; i >= 0; i -= 1) {
        let midDate = new Date(currentDate);
        midDate.setDate(midDate.getDate() - i);
        this.weekDays.push(midDate);
      }
      for (let i = 1; i <= 7 - getDay; i += 1) {
        let midDate = new Date(currentDate);
        midDate.setDate(midDate.getDate() + i);
        this.weekDays.push(midDate);
      }
    },
    weekPre() {
      const d = this.weekDays[0];
      d.setDate(d.getDate() - 7);
      this.initData(d);
    },
    weekNext() {
      const d = this.weekDays[6];
      d.setDate(d.getDate() + 7);
      this.initData(d);
    },
    getCurrentSchedule(data) {
      let obj = {
        midArr: [],
        background: ''
      }
      let currrentClickStartTime = new Date(data).getTime() - 28800000;
      let currrentClickEndTime = (new Date(data).getTime() - 28800000) + 86400000;
      obj.midArr = this.scheduleList.filter(item => item.startDateTime >= currrentClickStartTime && item.endDateTime < currrentClickEndTime);

      if (new Date().getTime() > currrentClickEndTime) {
        obj.background = "#fcefe6 !important";
      } else {
        obj.background = "#f0f3fc !important";
      }
      return obj;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/themes/css/traditionalIndex.scss";
</style>