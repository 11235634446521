import {sendAjax} from './escalation.js'
/*
* RECORD_ROUTES_NUMS(time); 记录规定时间内PV（浏览器地址发生变化）数量
* RECORD_CLICK_NUMS(time); 记录规定时间内PV（点击）数量
* */
(function (e) {
    var sdkVersion = '1.0.0', //sdk版本
        timer = null, //公共定时器
        timer1 = null, //独立监听定时器
        routerTrack = []; //指定时间内的轨迹

    RECORD_CLICK_NUMS(10000); // 开启PV（点击）数量记录

    function COMPUTED_Time(time) {
        clearInterval(timer);
        if (time) {
            if (routerTrack.length > 0) {
                sendAjax({uri: '/bus/graphql/trace', params: routerTrack});
            }
            routerTrack = [];
            timer = setInterval(() => {
                COMPUTED_Time(time);
            }, time)
        }
    }

    function WATCH_HERF() { // 监听浏览器uri的变化
        var curUrl = window.location.href;
        clearInterval(timer1);
        timer1 = setInterval(() => {
            if (window.location.href !== curUrl) {
                curUrl = window.location.href;
                routerTrack.push({
                    moduleName: 'workspace',
                    moduleText: '门户',
                    attributes: {
                        chunkText: document.title
                    },
                    requestUrl: curUrl,
                    type: 'ACC'
                });
            }
        }, 50);
    }

    /*
    * 目前为全局点击，点击任意操作均会触发点击事件，节点上添加 report-key 会执行信息记录
    * */
    function CLICK_ONCE() { // 点击记录
        window.addEventListener('click', (e) => {
            const reportKey = e.target.getAttribute("report-key");
            if (reportKey) {
                setTimeout(() => {
                    routerTrack.push({
                        moduleName: 'workspace',
                        moduleText: '门户',
                        attributes: {
                            chunkText: reportKey == '首页' ? reportKey : document.title,
                        },
                        requestUrl: window.location.href,
                        type: 'ACC'
                    });
                }, 500)
            }
        });
    }

    function RECORD_ROUTES_NUMS(time) {
        WATCH_HERF();
        COMPUTED_Time(time); //触发指定时间定时器
    }

    function RECORD_CLICK_NUMS(time) {
        CLICK_ONCE();
        COMPUTED_Time(time); //触发指定时间定时器
    }
})(window)