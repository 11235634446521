import VueApollo from 'vue-apollo'
import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloLink} from 'apollo-link'
import store from './store/index'

const customFetch = (uri, options) => {
    const id_token = store.state.oidcStore.id_token;
    return fetch(uri + '?id_token_hint=' + id_token, options);
}

var appcenterHttpLink = createHttpLink({
    uri: window.commonApi.APPCENTER_URL,
})

var newsHttpLink = createHttpLink({
    uri: window.commonApi.NEWS_URL
})
var taskapiHttpLink = createHttpLink({
    uri: window.commonApi.TASKAPI_URL
})

var notificationHttpLink = createHttpLink({
    uri: window.commonApi.NOTIFICATION_URL
})

var iamHttpLink = createHttpLink({
    uri: window.commonApi.IAM_URL
})

var calendarHttpLink = createHttpLink({
    uri: window.commonApi.CALENDAR_URL
})
var builtinHttpLink = createHttpLink({
    uri: window.commonApi.BUS_URL
})
var traceHttpLink = createHttpLink({
    uri: window.commonApi.TRACE_URL
})
var builtinIdtokenHttpLink = createHttpLink({
    uri: window.commonApi.BUS_URL,
    fetch: customFetch
})
var workspaceHttpLink = createHttpLink({
    uri: window.commonApi.WORKSPACE_URL
})

var cache = new InMemoryCache({
    dataIdFromObject: o => {
        o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`
    },
})

var middlewareLink = new ApolloLink((operation, forward) => {
    const token = store.state.oidcStore.access_token;
    operation.setContext({
        headers: {
            Authorization: `Bearer ${token}` || null,
        }
    })
    return forward(operation)
})

var defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

var appcenterLinkStr = middlewareLink.concat(appcenterHttpLink);
var appcenterApolloClient = new ApolloClient({
    link: appcenterLinkStr,
    cache: cache
})

var newsLinkStr = middlewareLink.concat(newsHttpLink);
var newsApolloClient = new ApolloClient({
    link: newsLinkStr,
    cache: cache
})

var taskapiLinkStr = middlewareLink.concat(taskapiHttpLink);
var taskapiApolloClient = new ApolloClient({
    link: taskapiLinkStr,
    cache: cache
})

var notificationLinkStr = middlewareLink.concat(notificationHttpLink);
var notificationApolloClient = new ApolloClient({
    link: notificationLinkStr,
    cache: cache
})

var iamLinkStr = middlewareLink.concat(iamHttpLink);
var iamApolloClient = new ApolloClient({
    link: iamLinkStr,
    cache: cache
})

var calendarLinkStr = middlewareLink.concat(calendarHttpLink);
var calendarApolloClient = new ApolloClient({
    link: calendarLinkStr,
    cache: cache
})

var builtinLinkStr = middlewareLink.concat(builtinHttpLink);
var builtinApolloClient = new ApolloClient({
    link: builtinLinkStr,
    cache: cache
})

var traceLinkStr = middlewareLink.concat(traceHttpLink);
var traceApolloClient = new ApolloClient({
    link: traceLinkStr,
    cache: cache
})

var builtinIdtokenLinkStr = middlewareLink.concat(builtinIdtokenHttpLink);
var builtinIdtokenApolloClient = new ApolloClient({
    link: builtinIdtokenLinkStr,
    cache: cache
})

var workspaceLinkStr = middlewareLink.concat(workspaceHttpLink);
var workspaceApolloClient = new ApolloClient({
    link: workspaceLinkStr,
    cache: cache
})

window.apolloProvider = new VueApollo({
    clients: {
        appcenter: appcenterApolloClient,
        news: newsApolloClient,
        taskapi: taskapiApolloClient,
        notification: notificationApolloClient,
        iam: iamApolloClient,
        calendar: calendarApolloClient,
        builtin: builtinApolloClient,
        trace: traceApolloClient,
        builtinIdtoken: builtinIdtokenApolloClient,
        workspace: workspaceApolloClient
    },
    defaultClient: appcenterApolloClient,
    defaultOptions: defaultOptions
})

export default apolloProvider