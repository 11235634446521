<template>
  <div id="app" :style="{minWidth:!getMobile? '1000px' : ''}">
    <router-view></router-view>
  </div>
</template>
<script>
import layout from "@/views/pc/layout/index"
import {mapActions, mapState} from "vuex"
import gql from "@/graphql/builtin"

export default {
  components: {layout},
  computed: {
    ...mapState(["userConfig"]),
    getAuth() {
      return this.$store.getters["oidcStore/oidcAccessToken"];
    },
    getIdToken() {
      return this.$store.state.oidcStore.id_token;
    },
  },
  created() {
    //加载缓存的全局头尾和主题,轮播图
    let userConfig = localStorage.getItem('userConfig:set');
    userConfig && this.$store.commit('updateConfig', JSON.parse(userConfig));
  },
  watch: {
    getAuth: {
      handler(value) {
        if (value) {
          this.$store.dispatch("getConfigSetting", { name: "USER" });
          this.userLoaded();
        }
      },
      immediate: true
    },
    userConfig: {
      deep: true,
      handler(value) {
        if(value.chooseTheme){
          let arr = value.chooseTheme.split("##");
          var body = document.getElementsByTagName("body");
          body[0].setAttribute("data-theme", arr[0]);
          this.$store.state.theme = arr[0];
        }
      }
    },
    getIdToken: {
      deep: true,
      immediate: true,
      handler(oldV) {
        if (oldV) {
          apolloProvider.clients.builtinIdtoken.query({
            client: 'builtin',
            fetchPolicy: "no-cache",
            query: gql.getPosts,
            variables: {
              first: 1,
              offset: 0
            }
          }).catch(error => {
            try {
              if (JSON.stringify(error).indexOf('OIDC_BC_LOGOUT') !== -1) {
                this.signOutOidc();
              }
            } catch (e) {
              console.log(e);
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions(["getLoginInfo"]),
    ...mapActions('oidcStore', ["signOutOidc"]),
    userLoaded() {
      this.getLoginInfo();
    },
    getMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (flag === null) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>
<style lang="scss">
html, body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  font-size: 14px;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: #f9f9f9;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

ul {
  li {
    list-style: none;
  }
}
</style>
