import gql from 'graphql-tag'

const scheduleCenter = {
    // 查询日程列表（可根据id查询）
    userSchedules: gql`query userSchedules($userId: String, $filter: ScheduleFilter){
        userSchedules(userId: $userId, filter:$filter){
            id
            tags
            createTime
            title
            startTime
            endTime
            creator {
                name
                account
            }
            system {
                clientId
            }
            hosts {
                name
                account
            }
            calendar {
                id
                name
                userFilters
                colour
                creator {
                    name
                    account
                }
                system {
                    clientId
                }
            }
        }
    }`,
    // 查询校历
    schoolCalendar: gql`query schoolCalendar($year: Int, $term: Int, $filter: SchoolCalendarFilter){
        schoolCalendar(year: $year, term: $term, filter: $filter){
            id
            startTime
            endTime
            term
            termName
            year
            memo
            createTime
            updateTime
            creator {
                name
                account
            }
        }
    }`,
    // 查询校历设置
    schoolCalendarDay: gql`query schoolCalendarDay($filter: SchoolCalendarDayFilter){
        schoolCalendarDay(filter: $filter){
            id
            sign
            memo
            dateSetting
            signStartTime
            signEndTime
        }
    }`,
    // 查询日程详情
    schedule: gql`query schedule($id: String!, $system: String){
        schedule(id: $id, system: $system){
            id
            title
            allDay
            startTime
            endTime
            description
            address
            userFilters
            attachments
            createTime
            updateTime
            templateFlag
            leader
            creator {
                name
                account
            }
            hosts {
                account
                name
            }
            recurring
            reminder
            system {
                name
                clientId
            }
            calendar {
                id
                name
                userFilters
            }
        }
    }`,
    // 新增日程
    saveSchedule: gql`mutation saveSchedule($schedule: ScheduleInput!){
        saveSchedule(schedule: $schedule){
            id
        }
    }`,
    // 删除日程
    deleteSchedule: gql`mutation deleteSchedule($id: String!){
        deleteSchedule(id: $id){
            id
        }
    }`
}
export default scheduleCenter;