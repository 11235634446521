<template>
  <div class="myHeader">
    <div class="myLogo">
      <img
        :src="userConfig && userConfig.systemLogoImageUrl"
        @click="reload"
        alt=""
      />
      <span>{{ userConfig && userConfig.systemName }}</span>
    </div>
    <el-input
      class="mySearchInput"
      placeholder="Search for..."
      prefix-icon="el-icon-search"
      size="small"
      v-model="searchText"
    />
    <ul class="myInfo">
      <!-- <li>-->
      <!--    <el-badge is-dot class="badgeItem" type="success">  -->
      <!--      <i class="el-icon-message"/>  -->
      <!--    </el-badge>  -->
      <!--    <img class="openIcon" src="@/assets/images/icon_newindex.png"-->
      <!--         @click="$store.commit('updateDrawerVisiable',{type:'btt'})" alt="">-->
      <!-- </li>-->
      <el-popover
        placement="bottom"
        :width="350"
        trigger="hover"
        popper-class="popover_readDialog"
      >
        <template v-if="getAllUnreadNowInfos.length > 0">
          <div class="popTop">
            <span class="titleContent"
              >未读（{{ getAllUnreadNowInfos.length }}）</span
            >
            <span class="titleDoat" @click="toRead(getAllUnreadNowInfos, 'all')"
              >全部标为已读</span
            >
          </div>
          <ul class="popBottom">
            <li
              class="popBottomLi"
              v-for="(nowInfo, noInfoIndex) in getAllUnreadNowInfos"
              :key="noInfoIndex"
            >
              <p class="titleArea">
                <img
                  class="img_svg"
                  src="@/assets/images/svg/doatRead.svg"
                  alt=""
                />
                <span
                  class="title overflowEllipsis"
                  :title="nowInfo.node.name"
                  @click="
                    pushUrl(
                      isJSON(nowInfo.node.content)
                        ? filterVal(nowInfo.node.content, 'url')
                        : ''
                    )
                  "
                >
                  {{ nowInfo.node.name ? nowInfo.node.name : "无标题" }}
                </span>
                <el-popover
                  placement="top"
                  trigger="hover"
                  popper-class="popover_small"
                >
                  <span class="areadyRead">标记已读</span>
                  <span
                    slot="reference"
                    class="doat"
                    @click.stop="toRead(nowInfo, 'one')"
                  ></span>
                </el-popover>
              </p>
              <p
                class="content overflowEllipsis_2"
                v-html="
                  isJSON(nowInfo.node.content)
                    ? changeMarkFromHtml(filterVal(nowInfo.node.content, 'des'))
                    : changeMarkFromHtml(nowInfo.node.content)
                "
              ></p>
              <p class="time">{{ nowInfo.node.createTime }}</p>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="noneDate">暂无待阅的消息</div>
        </template>
        <li slot="reference">
          <el-badge
            class="badgeItem"
            type="danger"
            :value="getAllUnreadNowInfos.length"
            :max="99"
            :hidden="getAllUnreadNowInfos.length == 0"
          >
            <i class="el-icon-bell" />
          </el-badge>
        </li>
      </el-popover>
      <li>
        <el-dropdown @command="infoCommand" class="loginInfo">
          <div>
            <!-- <img
              class="logo_name"
              v-if="userConfig && userConfig.userLogoImageUrl"
              :src="userConfig.userLogoImageUrl"
              alt=""
            /> -->
            <!-- v-else -->
            <span class="logo_name">{{
              userInfo && userInfo.name ? userInfo.name.slice(0, 1) : ""
            }}</span>
            您好，{{ userInfo && userInfo.name ? userInfo.name : ""
            }}<i class="el-icon-arrow-down el-icon--right" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="'safeSetting'"
              >安全设置</el-dropdown-item
            >
            <el-dropdown-item :command="'identity'">身份信息</el-dropdown-item>
            <el-dropdown-item :command="'logout'">注销登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
    <div class="themeToggle">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ selectTheme.text
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :key="idx"
            v-for="(theme, idx) in themeOptions"
            :command="theme"
            >{{ theme.text }}版</el-dropdown-item
          >
          <!-- <el-dropdown-item :command="{name:'monochrome',text:'单色'}">单色版</el-dropdown-item>
          <el-dropdown-item :command="{name:'mix',text:'混色'}">混色版</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <IdentityCard
      :showMesCard="showMesCard"
      @closeCard="closeCard"
      :positionMes="getPosition"
    />
    <GlobalSearch v-if="isShowSearchFlag" :searchText="searchText" :leftX="calculateOffsetX" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IdentityCard from "../index/modules/identityCard";
import GlobalSearch from "@/views/pc/commondetail/globalSearch";

export default {
  name: "myNav",
  inject: [
    "getLocalTime",
    "changeMarkFromHtml",
    "isJSON",
    "filterVal",
    "toUri",
    "reload",
  ],
  components: { IdentityCard, GlobalSearch },
  mounted(){
    let awidth = document.getElementsByClassName('myLogo')[0].clientWidth;
    this.calculateOffsetX = (awidth-10+30)+'px';
  },
  data() {
    return {
      searchText: "",
      isShowSearchFlag: false,
      showMesCard: false,
      defaultLogo: require("@/assets/themes/images/logo.png"),
      isOpenDrawer: false,
      themeOptions: [
        { name: "color", text: "彩色" },
        { name: "monochrome", text: "单色" },
        { name: "mix", text: "混色" },
      ],
      selectTheme:{ name: "monochrome", text: "单色" },
      calculateOffsetX: '215px',
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapGetters("notificationCenter", ["getAllUnreadNowInfos"]),
    ...mapGetters(["getPosition"]),
    ...mapState(["userConfig"]),
    getPositionMes() {
      return (
        this.getPosition &&
        this.getPosition.positions &&
        this.getPosition.positions[0] &&
        this.getPosition.positions[0].post.name
      );
    },
  },
  created() {
    if (this.getAllUnreadNowInfos.length == 0) {
      this.$store.dispatch("notificationCenter/getNotification", {
        status: "unread",
        first: 100,
        findAll: "all",
      });
    }
  },
  watch: {
    searchText: {
      immediate: true,
      handler(oldV) {
        if (oldV) {
          this.isShowSearchFlag = true;
        } else {
          this.isShowSearchFlag = false;
        }
      },
    },
    userConfig: {
      handler(val) {
        if (val?.chooseTheme) {
          let arr = val.chooseTheme.split("##");
           this.selectTheme = { name: arr[0], text: arr[1] };
          // this.handleCommand({ name: arr[0], text: arr[1] });
        }

        if (val?.chooseThemes) {
          this.themeOptions = val.chooseThemes.map((t) => {
            return {
              name: t.split("##")[0],
              text: t.split("##")[1],
            };
          });
        }
      },
    },
  },
  methods: {
    ...mapActions("oidcStore", ["signOutOidc"]),
    loadDefault(e) {
      e.target.src = this.defaultLogo;
    },
    pushUrl(val) {
      if (val) {
        window.open(val);
      }
    },
    toRead(item, type) {
      let ids = [];
      if (type == "one") {
        ids.push(item.node.id);
      } else if (type == "all") {
        item.forEach((item) => {
          ids.push(item.node.id);
        });
      }
      this.$store.dispatch("notificationCenter/markRead", ids);
    },
    closeCard() {
      this.showMesCard = false;
    },
    handleCommand(command) {
      this.selectTheme = command;
      this.$store.state.theme = command.name;
      var body = document.getElementsByTagName("body");
      body[0].setAttribute("data-theme", this.$store.state.theme);
    },
    infoCommand(command) {
      if (command == "logout") {
        this.signOutOidc();
      } else if (command == "identity") {
        this.showMesCard = true;
      } else if (command == "safeSetting") {
        this.toUri("/sso/user");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/themes/css/header.scss";
</style>
<style lang="css">
.popover_readDialog {
  min-height: 150px;
  padding: 10px 20px;
  top: 48px !important;
}

.noneDate {
  font-size: 16px;
  display: flex;
  line-height: 150px;
  justify-content: center;
}

.popover_small {
  min-width: 70px !important;
  width: 70px !important;
  padding: 0 !important;
}

.popover_small .popper__arrow::after {
  border-top-color: rgba(0, 0, 0, 0.9) !important;
}

.popover_small .areadyRead {
  color: white;
  padding: 3px 0;
  width: 70px;
  height: 100%;
  text-align: center;
  display: block;
  line-height: 20px;
  border-radius: 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.9);
}

.logo_name {
  height: 38px;
  line-height: 38px;
  width: 38px;
  display: inline-block;
  color: #878d97;
  background: #eef1f7;
  border-radius: 50%;
  font-size: 20px;
  font-family: cursive;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: 1px 0px 1px #d3d7de;
}
</style>
