import gql from 'graphql-tag'
const todoCenter={
	process:gql`query process($id:String,$entry:String,$system:String){
		process(id:$id,entry:$entry,system:$system){
			id
			name
			uri
			entry
			status
			create
			update
			priority
			tags
			rating
			review
			pendingTasks
			app {
			  id
			  code
			  name
			  uri
			  tags
			  icon
			  department
			  abbreviation
			  description
			  recommend
			  palette
			  rating
			  rated
			  system
			}
			owner {
			  id
			  account
			  name
			}
			milestone {
				name
				percent
				status 
			}
			milestones {
				name
				percent
				status 
			}
			system{
			  id
			  name
			}
		  tasks{
			id
			name
			actionUser{
				name
			}
			actionTime
			actionName
		  }
			
		}
	}`,
	todo:gql`query tasks($cate: String!,$userId:String){
		tasks(cate:$cate,userId:$userId){
			name,
			id,
			assignTime,
			uri,
			actionName,
			expireTime,
			process{
				id,
				name,
				uri,
				tags,
				entry,
				create,
				owner{
					id,
					name,
					account
				}
				app{
					name
				}
				system{
					id
					name
				}
			}
		}
	}`,
	doing:gql`query processes($cate:String!,$userId:String,$page:Int,$size:Int,$filter: ProcessFilter){
		processes(cate:$cate,userId:$userId,page:$page,size:$size,filter:$filter){
			totalCount,
			edges{
				node{
					id,
					name,
					uri,
					entry,
					create,
					pendingTasks,
					update,
					app{
						name
					}
					system{
						id
						name
					}
					owner{
						name
					}
					milestone {
						name
						percent
						status 
					}
				}
			}
		}
	}`,
	done:gql`query processes($cate:String!,$userId:String,$page:Int,$size:Int,$filter: ProcessFilter){
		processes(cate:$cate,userId:$userId,page:$page,size:$size,filter:$filter){
			totalCount,
			edges{
				node{
					id,
					name,
					uri,
					entry,
					create,
					pendingTasks,
					update,
					app{
						name
					}
					system{
						id
						name
					}
					owner{
						name
					}
				}
			}
		}
	}`,
	cc:gql`query processes($cate:String!,$userId:String,$page:Int,$size:Int,$filter: ProcessFilter){
		processes(cate:$cate,userId:$userId,page:$page,size:$size,filter:$filter){
			totalCount,
			edges{
				node{
					id,
					name,
					uri,
					entry,
					create,
					pendingTasks,
					update,
					app{
						name
					}
					system{
						id
						name
					}
					owner{
						name
					}
				}
			}
		}
	}`,
	completed:gql`query processes($cate:String!,$userId:String,$page:Int,$size:Int,$filter: ProcessFilter){
		processes(cate:$cate,userId:$userId,page:$page,size:$size,filter:$filter){
			totalCount,
			edges{
				node{
					id,
					name,
					uri,
					entry,
					create,
					pendingTasks,
					update,
					app{
						name
					}
					system{
						id
						name
					}
					owner{
						name
					}
					milestone{
						percent
					}
				}
			}
		}
	}`
}
export default todoCenter;