import gql from 'graphql-tag'
/*
* 上报方式
* */
export function sendAjax({uri = '', params}) {
    params.forEach(request => {
        try {
            apolloProvider.clients.trace.mutate({
                client: 'trace',
                mutation: gql `mutation addLog($log:LogInput){addLog(log:$log){ip}}`,
                variables: {
                    log: request
                },
            }).then(res => {

            }).catch(error => {

            })
        } catch (error) {
            console.log(error);
        }
    })
}

export async function sendBeacon({url = '', params}) {
    if (navigator?.sendBeacon && url) {
        const isSuccess = await navigator?.sendBeacon(url, JSON.stringify(params));
        if (isSuccess) return true;
    }
    return false;
}

export function sendImg({img = '', params}) {
    return new Promise((resolve, reject) => {
        // const imageData  = objectToQueryString(params);
        const imageData = JSON.stringify(params);
        const img_o = new Image();
        img_o.onload = () => resolve(true);
        img_o.onerror = () => reject(false);
        img_o.src = `${img}?${imageData}`;
    })
}
