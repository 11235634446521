import gql from 'graphql-tag'

const notificationCenter = {
    notifications: gql`query notifications($status:String, $state:Int, $name: String, $startTime:Date, $endTime:Date, $first:Int, $offset:Int, $filter:Map){
		notifications(status:$status,state:$state,name:$name,startTime:$startTime,endTime:$endTime,first:$first,offset:$offset,filter:$filter){
			totalCount
			edges{
				node{
					id
					name
					application
					channel
					content
					contentAbstract
					body
					html
					email
					phone
					user{
						id
						name
						account
						email
						phone
						enterprise{
							id
							name
							domain
						}
					}
					state
					status
					errMsg
					createTime
					finishTime
				}
			}
		}
	}`,
    // 标记已读
    updateNotificationStatus: gql`mutation updateNotificationStatus($ids:[String!]!, $action:String!){
		updateNotificationStatus(ids:$ids, action:$action){
			id
			name
			application
			channel
			content
			contentAbstract
			body
			html
			email
			phone
			user{
				id
				name
				account
				email
				phone
				enterprise{
					id
					name
					domain
				}
			}
			state
			status
			errMsg
			createTime
			finishTime
		}
	}`,
}
export default notificationCenter;