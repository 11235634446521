import {vuexOidcCreateStoreModule} from 'vuex-oidc'
import {oidcSettings} from '../config/oidc'
import todoCenter from './todoCenter' //待办中心
import appCenter from "./appCenter" //应用中心
import announceCenter from "./announceCenter" //公告中心
import scheduleCenter from "./scheduleCenter" //日程中心
import notificationCenter from "./notificationCenter" //提醒中心
import $users from "@/graphql/users"
import gql from "@/graphql/builtin";

const state = {
    theme: "monochrome",//主题
    userInfo: null,
    position: {},
    isOpenDrawerVisiable: false,  // 控制抽屉中的 index 页面
    direction: 'rtl',  // 控制抽屉中的 index 页面显示方向
    isAredlyTop: 'yes',  // 移动端主体部分是否触顶
    isHoverShowNav: false, // 悬浮显示菜单
    defaultmenusList: [],
    canvasmenusList: [],
    userConfig: null,
}
const getters = {
    getIsOpenDrawerVisiable: () => state.isOpenDrawerVisiable,
    getDirection: () => state.direction,
    getIsAredlyTop: () => state.isAredlyTop,
    getPosition: () => state.position
}

const actions = {
    getLoginInfo({state}) {
        apolloProvider.clients.iam.query({
            client: 'iam',
            query: $users.me,
            fetchPolicy: "no-cache",
        }).then(res => {
            state.userInfo = res.data.me;
            state.position = res.data.me;
        }).catch(error => {
            console.log(error);
        })
    },
    getConfigSetting({state, commit}, {name}) {
        apolloProvider.clients.workspace.query({
            query: gql.preference,
            variables: {
                name: name
            },
            client: 'workspace',
            fetchPolicy: "no-cache",
        }).then(res => {
            let data = res.data.preference;
            if (data && data.valueString) {
                if (data.name == 'USER') {
                    localStorage.setItem('userConfig:set', data.valueString);
                    commit('updateConfig', JSON.parse(data.valueString));
                }
            } else {
                state.userConfig = null;
            }
        }).catch(error => {
            console.log(error);
        })
    },
}

const mutations = {
    updateConfig(state, json) {
        state.userConfig = json;
    },
    updateDrawerVisiable(state, value) {
        if (value.isAutoChange) {
            state.isOpenDrawerVisiable = false;
        } else {
            state.isOpenDrawerVisiable = !state.isOpenDrawerVisiable;
        }
        state.direction = value.type;
    },
    updateIsAredlyTop(state, value) {
        state.isAredlyTop = value;
    },
    updateIsHoverShowNav(state) {
        state.isHoverShowNav = !state.isHoverShowNav;
    },
    updateDefaultMenusList(state, list) {
        let filterUserList = list.filter(item => item.uri.indexOf('/manage') == -1 && item.text.indexOf('role') == -1);
        state.defaultmenusList = filterUserList;
    },
    updateCanvasmenusList(state, list) {
        let filterUserList = list.filter(item => item.uri.indexOf('/manage') == -1 && item.text.indexOf('role') == -1);
        state.canvasmenusList = filterUserList;
    }
}

const modules = {
    todoCenter: todoCenter,
    appCenter: appCenter,
    announceCenter: announceCenter,
    scheduleCenter: scheduleCenter,
    notificationCenter: notificationCenter,
    oidcStore: vuexOidcCreateStoreModule(
        oidcSettings,
        // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
        // spread your oidcSettings and define a userStore of your choice
        // {
        //     ...oidcSettings,
        //     //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
        // },
        // Optional OIDC store settings
        {
            namespaced: true,
            dispatchEventsOnWindow: true

        },
        // Optional OIDC event listeners
        {
            userLoaded: (user) => console.log('OIDC user is loaded:', user),
            userUnloaded: () => console.log('OIDC user is unloaded'),
            accessTokenExpiring: () => console.log('Access token will expire'),
            accessTokenExpired: () => console.log('Access token did expire'),
            silentRenewError: () => console.log('OIDC user is unloaded'),
            userSignedOut: () => console.log('OIDC user is signed out'),
            oidcError: (payload) => console.log('OIDC error', payload)
        }
    )

}

export default {
    modules,
    state,
    getters,
    actions,
    mutations
}