<template>
  <div class="global-search-contain" :style="{'left': leftX}">
    <!--   导航栏   -->
    <ul class="global-search-ul">
      <li class="global-search-li"
          v-for="(item,index) in navList"
          :key="index"
          :class="chooseTag == item.type?'active':''"
          @click="updateStatus(item)"
      >{{ item.title }}
      </li>
    </ul>
    <!--   内容区域   -->
    <ul class="global-search-app-ul">
      <li class="global-search-app-li" v-for="(item,index) in navAppList" :key="index">
        <p class="search-app-title">
          <span style="font-weight: bold;">{{ item.title }}</span>
          <span style="font-size: 12px;"> 中搜索“<span style="color: red;">
            {{ searchText }}
          </span>”的结果 ({{ item.total }}条结果)</span>
        </p>
        <ul class="search-app-ul">
          <li class="search-app-li" v-for="(iApp,iKey) in item.list">
            <span class="app-title" v-html="replaceText(iApp.title)"/>
            <div class="app-right">
              <span class="app-content"
                    v-html="deleteBr(isJSON(iApp.content) ? changeMarkFromHtml(filterVal(iApp.content, 'des')) : changeMarkFromHtml(iApp.content))"/>
              <span class="app-date">{{ iApp.date }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "globalSearch",
  inject: ['getLocalTime', 'changeMarkFromHtml', 'isJSON', 'filterVal'],
  data() {
    return {
      chooseTag: 'all',
      navList: [
        {type: 'all', title: '全部'},
        {type: 'notification', title: '提醒'},
        {type: 'todo', title: '待办'},
        {type: 'app', title: '应用'},
        {type: 'notice', title: '公告'},
        {type: 'schedule', title: '日程'},
      ],
      navAppList: [],
      todoProcess: []
    }
  },
  props: {
    searchText: {
      type: String,
      default: () => ''
    },
    leftX: {
      type: String,
      default: () => '215px'
    }
  },
  computed: {
    ...mapGetters('notificationCenter', ['getAllNowInfos']),
    ...mapState("todoCenter", ["dataSource"]),
    ...mapGetters("announceCenter", ["getAnnounceList"]),
    ...mapGetters("appCenter", ["getTagApps"]),
    ...mapGetters("scheduleCenter", ["getScheduleList"]),
    todoDatas() {
      return this.dataSource['todo'];
    }
  },
  watch: {
    searchText: {
      immediate: true,
      handler(oldV) {
        if (oldV && (this.chooseTag == 'all' || this.chooseTag == 'notification')) {
          this.$store.dispatch('notificationCenter/getNotification', {first: 100000, offset: 0, name: oldV})
        }
        if (oldV && (this.chooseTag == 'all' || this.chooseTag == 'todo')) {
          this.todoProcess = this.todoDatas.filter(item => item.process.name.indexOf(oldV) > -1);
        }
        if (oldV && (this.chooseTag == 'all' || this.chooseTag == 'app')) {
          let obj = {};
          obj['seq'] = oldV;
          this.$store.dispatch("appCenter/updateQuery", obj);
        }
        if (oldV && (this.chooseTag == 'all' || this.chooseTag == 'notice')) {
          this.$store.commit('announceCenter/filterNoticeList', oldV);
        }
        if (oldV && (this.chooseTag == 'all' || this.chooseTag == 'schedule')) {
          let filter = {
            startTime: {eq: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()},
            endTime: {eq: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime() + 86399999},
            keyword: {eq: oldV}
          }
          this.$store.dispatch('scheduleCenter/getScheduleListFun', filter);
        }
        setTimeout(() => {
          this.getSearchContent();
        }, 500)
      }
    },
    chooseTag: {
      immediate: true,
      handler(oldV) {
        setTimeout(() => {
          this.getSearchContent();
        }, 500)
      }
    }
  },
  methods: {
    updateStatus(item) {
      this.chooseTag = item.type;
    },
    deleteBr(item) {
      return item.replace('<br>', '');
    },
    replaceText(item) {
      return item.replace(this.searchText, `<span style="color:red;">${this.searchText}</span>`);
    },
    getSearchContent() {  // 根据类型返回指定集合 默认全部[{},{},{},{},{}]
      this.getAllNowInfos.forEach(item => {
        item.title = item.node.name;
        item.content = item.node.content;
        item.date = item.node.createTime;
      })
      this.todoProcess.forEach(item => {
        item.title = item.process.name;
        item.date = this.getLocalTime(item.process.create, 'YYYY-MM-DD HH:mm:ss');
      })
      this.getAnnounceList.forEach(item => {
        item.title = item.node.title;
        item.date = this.getLocalTime(item.node.createDateTime, 'YYYY-MM-DD HH:mm:ss');
      })
      this.getScheduleList.forEach(item => {
        item.title = item.title;
        item.date = this.getLocalTime(item.createTime, 'YYYY-MM-DD HH:mm:ss');
      })
      this.getTagApps.forEach(item => {
        item.title = item.text;
        item.date = item.created;
      })
      if (this.chooseTag == 'all') {
        this.navAppList = [
          {title: '提醒', total: this.getAllNowInfos.length, list: this.getAllNowInfos},
          {title: '待办', total: this.todoProcess.length, list: this.todoProcess},
          {title: '应用', total: this.getTagApps.length, list: this.getTagApps},
          {title: '公告', total: this.getAnnounceList.length, list: this.getAnnounceList},
          {title: '日程', total: this.getScheduleList.length, list: this.getScheduleList}
        ]
      } else if (this.chooseTag == 'notification') {
        this.navAppList = [
          {title: '提醒', total: this.getAllNowInfos.length, list: this.getAllNowInfos}
        ]
      } else if (this.chooseTag == 'todo') {
        this.navAppList = [
          {title: '待办', total: this.todoProcess.length, list: this.todoProcess}
        ]
      } else if (this.chooseTag == 'app') {
        this.navAppList = [
          {title: '应用', total: this.getTagApps.length, list: this.getTagApps}
        ]
      } else if (this.chooseTag == 'notice') {
        this.navAppList = [
          {title: '公告', total: this.getAnnounceList.length, list: this.getAnnounceList}
        ]
      } else if (this.chooseTag == 'schedule') {
        this.navAppList = [
          {title: '日程', total: this.getScheduleList.length, list: this.getScheduleList}
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/themes/css/animate/commonAnmate.scss";

.global-search-contain {
  width: 800px;
  height: 500px;
  overflow-y: scroll;
  background: #F9F9F9;
  position: fixed;
  top: 60px;
  left: 215px;
  z-index: 2;
  padding: 15px 20px;
  box-shadow: 0 5px 15px #e0e3e6;
  border-radius: 0 0 8px 8px;
  animation: graduallyAnimate 0.5s linear;

  .global-search-ul {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    box-shadow: 0 0 15px #e7e2e2;

    .global-search-li {
      text-align: center;
      padding: 0 20px 5px 20px;
      width: 10%;
      border-bottom: 3px solid #F9F9F9;
      cursor: pointer;
      letter-spacing: 1px;

      &.active {
        color: #0A7CFA;
        font-weight: bold;
        border-bottom: 3px solid #0A7CFA;
      }
    }
  }

  .global-search-app-ul {
    margin-top: 30px;

    .global-search-app-li {
      margin-bottom: 15px;

      .search-app-li {
        padding: 10px 0;
        border-bottom: 1px dashed #6d7176;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .app-title {
          width: 220px;
          height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .app-right {
          display: flex;

          .app-content {
            width: 280px;
            height: 20px;
            margin-right: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
}
</style>