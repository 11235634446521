import gql from "@/graphql/notificationCenter"
import {Message} from 'element-ui'

const state = {
    loadingDataStart: false,
    readNowInfos: [],
    unreadNowInfos: [],
    readCount: 0,
    unreadCount: 0,
    allNowInfos: [], // 所有提醒 （无分页）
    allUnreadNowInfos:[]  // 所有未读的提醒 （无分页）
}
const getters = {
    getReadNowInfos: () => state.readNowInfos,
    getUnreadNowInfos: () => state.unreadNowInfos,
    getAllNowInfos: () => state.allNowInfos,
    getAllUnreadNowInfos: () => state.allUnreadNowInfos
}

const actions = {
    getNotification({state, commit, dispatch}, {status, name, first, offset, findAll}) {
        /*
        * status 已读未读状态
        * */
        commit('loadingData', true);
        apolloProvider.clients.notification.query({
            client: 'notification',
            fetchPolicy: "no-cache",
            query: gql.notifications,
            variables: {
                first: first ? first : 10,
                offset: offset ? offset : 0,
                status: status && status,
                name: name ? name : '',
                state: 1
            }
        }).then(res => {
            let datas = res.data.notifications;
            commit('handleNotification', {datas, status, findAll});
            commit('loadingData', false);
        }).catch(error => {
            console.log(error);
            commit('loadingData', false);
        });
    },
    // 标记已读
    markRead({state, commit, dispatch}, item) {
        let ids = item;
        apolloProvider.clients.notification.mutate({
            client: 'notification',
            mutation: gql.updateNotificationStatus,
            variables: {
                ids: ids,
                action: 'read'
            }
        }).then(res => {
            Message.success("已标记已读！");
            dispatch('getNotification', {
                status: 'unread'
            });
        }).catch(error => {
            console.log(error);
        });
    }
}

const mutations = {
    handleNotification(state, {datas, status, findAll}) {
        if (findAll == 'all'){
            state.allUnreadNowInfos = datas.edges;
        }
        if (status == 'read' && findAll !== 'all') {
            state.readNowInfos = datas.edges;
            state.readCount = datas.totalCount;
        } else if (status == 'unread' && findAll !== 'all') {
            state.unreadNowInfos = datas.edges;
            state.unreadCount = datas.totalCount;
        } else {
            state.allNowInfos = datas.edges;
        }
    },
    //骨架屏
    loadingData(state, load_bool) {
        state.loadingDataStart = load_bool;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}