import {publicMenthods} from "@/mixins/utils";

let terminal = 'pc';
if (publicMenthods.getMobile()) terminal = 'wechat';
export const oidcCallback = {
    path: '/oidcCallback',
    name: 'oidcCallback',
    component: () => import("@/components/common/oidcCallback"),
    meta: {
        isVuexOidcCallback: true,
        isPublic: true
    }
}

export const signError = {
    path: '/signin-oidc-error',
    redirect: '/'
}

export const E404 = {
    path: '/404',
    component: () => import('@/components/common/404.vue'),
    meta: {
        title: '404',
        isVuexOidcCallback: true,
        isPublic: true
    },
}

export const noAuth = {
    path: '/no-auth',
    component: () => import('@/components/common/no-auth.vue'),
    meta: {
        title: '无权限',
        isVuexOidcCallback: true,
        isPublic: true
    }
}

export const workbench = {
    path: '/workbench',
    name: 'workbench',
    component: () => import(`@/views/${terminal}/workbench/index`),
    redirect: "/workbench/index",
    children: [
        {
            path: '/workbench/index',
            name: 'workbenchIndex',
            component: () => import(`@/views/${terminal}/workbench/index`),
            meta: {
                title: '工作台',
                isVuexOidcCallback: true,
                isPublic: true
            }
        }
    ]
}

export const commondetail = {
    path: '/commondetail',
    name: 'commondetail',
    component: () => import(`@/views/${terminal}/commondetail/index`),
    redirect: "/commondetail/index",
    children: [
        {
            path: '/commondetail/index',
            name: 'commondetail',
            component: () => import(`@/views/${terminal}/commondetail/index`),
            meta: {
                title: '详情',
                isVuexOidcCallback: true,
                isPublic: true
            }
        }
    ]
}