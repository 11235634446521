import axios from 'axios'
import store from './store/index'
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(config => {
    const token = store.state.oidcStore.access_token;
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});
//返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
    //对响应数据做些事
    if (!res.data.success) {
        return Promise.resolve(res);
    }
    return res;
}, (error) => {
    console.log('网络异常')
    return Promise.reject(error);
});

// var getUrlParam = function getQueryVariable(variable) {
//     var query = window.location.hash.split("#")[2];
//     var vars = query.split("&");
//     for (var i = 0; i < vars.length; i++) {
//         var pair = vars[i].split("=");
//         if (pair[0] == variable) {
//             return pair[1];
//         }
//     }
//     return (false);
// }

export default axios;