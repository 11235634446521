import gql from "@/graphql/announceCenter"

const state = {
    loadingDataStart: false,
    loadingDetailStart: false,
    announceArr: [],
    annTotalCount: 0,
    announceClassfyArr: [],
    announceDetail: {},
    announceDetailNolimit: {},
    uploadFile:{}
}
const getters = {
    getAnnounceList: () => state.announceArr,
    getAnnounceListLength: () => state.annTotalCount,
    getAnnounceClassfyList: () => state.announceClassfyArr,
    getAnnounceDetail: () => state.announceDetail,
    getUploadFile: () => state.uploadFile,
}

const actions = {
    // 查询公告分类
    announceClassfyList({commit, state, dispatch}) {
        commit('loadingData', true);
        apolloProvider.clients.news.query({
            query: gql.publishedChannelService_loadAllChannels,
            client: 'news',
            fetchPolicy: "no-cache",
            variables: {}
        }).then((res) => {
            state.announceClassfyArr = res.data.publishedChannelService_loadAllChannels.edges;
            commit('loadingData', false);
            commit('handerData', state.announceClassfyArr);
        }).catch(error => {
            commit('loadingData', false);
            console.error(error);
        });
    },

    // 查询公告  （channelId 字符串）
    announceList({commit, state, dispatch}, payLoad) {
        let first = 10, offset = 0;
        if (payLoad && payLoad.first) {
            first = payLoad.first;
        }
        if (payLoad && payLoad.offset) {
            offset = payLoad.offset;
        }
        commit('loadingData', true);
        apolloProvider.clients.news.query({
            query: gql.publishedArticleIndexService_searchByChannel,
            client: 'news',
            fetchPolicy: "no-cache",
            variables: {
                first: first,
                offset: offset,
                channelId: payLoad && payLoad.channelId? payLoad.channelId : ''
            }
        }).then((res) => {
            state.announceArr = res.data.publishedArticleIndexService_searchByChannel.edges;
            state.annTotalCount = res.data.publishedArticleIndexService_searchByChannel.totalCount;
            commit('loadingData', false);
        }).catch(error => {
            console.error(error);
            commit('loadingData', false);
        });
    },
    // 查询公告详情
    getAnnounceDetail({commit, state, dispatch}, id) {
        commit('loadingDetail', true);
        apolloProvider.clients.news.query({
            query: gql.publishedArticleService_articleDetail,
            client: 'news',
            fetchPolicy: "no-cache",
            variables: {
                id: id
            }
        }).then(res => {
            state.announceDetail = res.data.publishedArticleService_articleDetail.entity;
            // dispatch('announceList');
            commit('loadingDetail', false);
        }).catch(error => {
            console.error(error);
            commit('loadingDetail', false);
        });
    },

    // 查询公告详情:预览用
    getAnnounceDetailNolimit({commit, state, dispatch}, id) {
        commit('loadingDetail', true);
        apolloProvider.clients.news.query({
            query: gql.articleService_articleDetail,
            client: 'news',
            fetchPolicy: "no-cache",
            variables: {
                id: id
            }
        }).then(res => {
            state.announceDetailNolimit = res.data.articleService_getById.entity;
            commit('loadingDetail', false);
        }).catch(error => {
            console.error(error);
            commit('loadingDetail', false);
        });
    },
    
    // 查询公告附件
    getUploadFile({commit, state, dispatch}, id) {
        commit('loadingDetail', true);
        apolloProvider.clients.news.query({
            query: gql.attachmentService_getArticleAttachment,
            client: 'news',
            fetchPolicy: "no-cache",
            variables: {
                articleId: id
            }
        }).then(res => {
            state.uploadFile = res.data.attachmentService_getArticleAttachment.edges[0];
            commit('loadingDetail', false);
        }).catch(error => {
            console.error(error);
            commit('loadingDetail', false);
        });
    }
}
const mutations = {
    //骨架屏
    loadingData(state, load_bool) {
        state.loadingDataStart = load_bool;
    },
    // 加载中。。。
    loadingDetail(state, load_bool) {
        state.loadingDetailStart = load_bool;
    },
    // 搜索过滤
    filterNoticeList(state,keywords){
        let titleList = state.announceArr.filter(item => item.node.title && item.node.title.indexOf(keywords) !== -1);
        let departmentList = state.announceArr.filter(item => item.node.departmentList && item.node.departmentList.indexOf(keywords) !== -1);
        state.announceArr = Array.from(new Set([...titleList, ...departmentList]));
    },
    // 处理成级联选择器需要的格式 树形结构
    handerData(state, array) {
        // 将值修改成页面中组件需要的格式
        for (let i = 0; i < array.length; i++) {
            array[i] = array[i].node;
            array[i].value = array[i].id;
            array[i].label = array[i].name;
        }
        let newArray = array.filter(item => {
            item.children = array.filter(item1 => item.id === item1.parent_id);
            if (item.children.length == 0) {
                delete item.children;
            }
            return !item.parent_id;
        })
        state.announceClassfyArr = newArray;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}