import gql from "@/graphql/appCenter"
import {Message} from 'element-ui'

const state = {
    loadingDataStart: false,
    appList: [],   // 所有app
    recommends: [], // 推荐
    tags: [],
    department: [],
    tagApps: [],  //经过重重筛选之后的apps
    favorites: [],  // 所有收藏
    favoriteApps: [],  // 过滤后的收藏
    recentApps: [],  // 最近使用的 apps
    zm: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"], //26个字母
    zimu: [],  //由tagApps拿到的字母
    appQuery: {   // 分类
        seq: '',
        tag: '',
        department: '',
        other: '',
        zimu: ''
    },
    reviewTotal: 0,
    reviewAndRate: [],
    appTagsIcon: []
}
const getters = {
    getAppList: state => state.appList,
    getFavorites: state => state.favorites,
    getFavoriteApps: state => state.favoriteApps,
    getTags: state => state.tags,
    getDepartments: state => state.department,
    getTagApps: state => state.tagApps,
    getRecommends: state => state.recommends,
    getReviewTotal: state => state.reviewTotal,
    getReviewAndRate: state => state.reviewAndRate,
    getRecentApps: state => state.recentApps,
    getAppTagsIcon: state => state.appTagsIcon,
}

const actions = {
    //拿所有的apps
    
    fetchAppList({commit, state, dispatch}, type) {
        commit('loadingData', true);
        apolloProvider.clients.appcenter.query({
            query: gql.userApps,
            variables: {},
            client: 'other',
            fetchPolicy: "no-cache",
        }).then(res => {
            let appList = res.data.userApps;
            if (appList && appList.length > 0) {
                state.appList = appList.sort((A, B) => new Date(B.updated).getTime() - new Date(A.updated).getTime());
            } else {
                state.appList = [];
            }
            commit('updateAppList');
            commit('updateAppAll');
            dispatch('favoriteGet');
            if (type == null || type !== 'other') {
                dispatch('getTagsIcon');
            }
            commit('loadingData', false);
        }).catch(error => {
            commit('loadingData', false);
            console.error(error)
        });
    },
    // 收藏列表
    favoriteGet({commit}) {
        commit('loadingData', true);
        apolloProvider.clients.appcenter.query({
            query: gql.userFavoriteApps,
            variables: {},
            client: 'other',
            fetchPolicy: "no-cache",
        }).then(res => {
            let favorites = [];
            if (res.data.userFavoriteApps) {
                res.data.userFavoriteApps.forEach(item => {
                    favorites.push(item.id);
                });
            }
            state.favorites = favorites;
            commit('favoriteReset', favorites);
            commit('loadingData', false);
        }).catch(error => {
            console.error(error);
            commit('loadingData', false);
        });
    },

    // 记录访问次数
    addFavoriteMethods({commit, dispatch}, {type, appId}) {  // type：1.点击应用访问  2.查看详情  3.收藏应用
        let record = {type, appId};
        apolloProvider.clients.appcenter.mutate({
            client: 'other',
            fetchPolicy: "no-cache",
            mutation: gql.createAppOperationRecord,
            variables: {
                record
            }
        }).then(res => {
            if (type == 3) {
                dispatch('favoriteGet');
                Message({
                    message: '收藏成功',
                    type: 'success'
                });
            }
        }).catch(error => {
            console.log(error);
        })
    },

    // 记录取消收藏收藏访问次数
    reduceFavoriteMethods({commit, dispatch}, id) {
        apolloProvider.clients.appcenter.mutate({
            client: 'other',
            fetchPolicy: "no-cache",
            mutation: gql.cancelAppCollect,
            variables: {
                app: id
            }
        }).then(res => {
            dispatch('favoriteGet');
            Message({
                message: '取消收藏成功！',
                type: 'success'
            });
        }).catch(error => {
            console.log(error);
        })
    },

    // 查询评价总数
    searchReviewTotal({commit, state}, appId) {
        apolloProvider.clients.appcenter.query({
            client: 'other',
            fetchPolicy: "no-cache",
            query: gql.appReviews,
            variables: {
                filter: {
                    app: {
                        eq: appId
                    }
                }
            }
        }).then(res => {
            state.reviewTotal = res.data.appReviews.totalCount;
        }).catch(error => {
            console.log(error)
        })
    },

    // 查询我的评分及评价
    getMeReviewAndRate({commit, state}, id) {
        apolloProvider.clients.appcenter.query({
            client: 'other',
            fetchPolicy: "no-cache",
            query: gql.userAppReviews,
            variables: {
                filter: {
                    app: {
                        eq: id
                    }
                }
            }
        }).then(res => {
            // 我的评分和评价
            state.reviewAndRate = res.data.userAppReviews.edges;
        }).catch(error => {
            console.log(error);
        });
    },

    // 新增评价及评分
    addReviewAndRate({commit, state}, review) {
        apolloProvider.clients.appcenter.mutate({
            client: 'other',
            fetchPolicy: "no-cache",
            mutation: gql.createAppReview,
            variables: {
                review: review
            }
        }).then(res => {
            this.$alert('感谢您的反馈。', '已提交', {
                confirmButtonText: '好',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${action}`
                    });
                }
            });
        }).catch(error => {
            console.log(error);
        })
    },

    // 查询最近使用的app
    async userRecentApps({state, commit}) {
        commit('loadingData', true);
        await apolloProvider.clients.appcenter.query({
            query: gql.userRecentApps,
            variables: {},
            client: 'other',
            fetchPolicy: "no-cache",
        }).then(res => {
            state.recentApps = res.data.userRecentApps;
            commit('loadingData', false);
        }).catch(error => {
            console.error(error);
            commit('loadingData', false);
        });
    },

    // 查询应用分类图标
    async getTagsIcon({state, commit}) {
        await apolloProvider.clients.appcenter.query({
            query: gql.appTags,
            variables: {},
            client: 'other',
            fetchPolicy: "no-cache",
        }).then(res => {
            state.appTagsIcon = res.data.appTags;
        }).catch(error => {
            console.error(error);
            commit('loadingData', false);
        });
    },

    updateQuery({commit, state}, {seq, tag, dep, other, zimu}) {
        state.appQuery.seq = seq || state.appQuery.seq;
        state.appQuery.tag = tag || state.appQuery.tag;
        state.appQuery.department = dep || state.appQuery.department;
        state.appQuery.other = other || state.appQuery.other;
        state.appQuery.zimu = zimu || state.appQuery.zimu;
        if (seq === '') {
            state.appQuery.seq = '';
        }
        if (tag === 'all') {
            state.appQuery.tag = '';
        }
        if (dep === 'all') {
            state.appQuery.department = '';
        }
        if (other === 'all') {
            state.appQuery.other = '';
        }
        if (zimu === '全部字母检索') {
            state.appQuery.zimu = '';
        }
        commit('updateAppAll');
    }
}

const mutations = {
    //骨架屏
    loadingData(state, load_bool) {
        state.loadingDataStart = load_bool;
    },

    favoriteReset(state, favorites) {
        state.favoriteApps = state.favorites.map(item1 => {
            let itemList = state.appList.filter(item2 => {
                return item2.id === item1;
            })[0]
            return itemList;
        }).filter(item => item);
    },

    updateAppList(state) {
        let appList = state.appList;
        let recommends = [];
        let tags = [];
        let department = [];
        if (appList && appList.length > 0) {
            tags = appList.map(item => item.tags && item.tags.split(",")[0]);
            department = appList.map(item => item.department && item.department.split(",")).flat(1);
            recommends = appList.filter(item => item.recommend && item.recommend > 0);
            tags = tags.filter(item => item);
            department = department.filter(item => item)
            tags = Array.from(new Set(tags));
            department = Array.from(new Set(department));
        }
        state.recommends = recommends;
        state.tags = tags;
        state.department = department;
    },

    updateAppAll(state) {
        let all_apps = state.appList;
        if (state.appQuery.seq) {
            all_apps = all_apps.filter((item) => {
                return item.text.indexOf(state.appQuery.seq) > -1 || item.text && item.text.indexOf(state.appQuery.seq) > -1;
            });
        }
        if (state.appQuery.tag) {
            if (state.appQuery.tag == '其他') {
                all_apps = all_apps.filter(item => item.tags == '' || item.tags == null);
            } else {
                all_apps = all_apps.filter((item) => {
                    return item.tags && item.tags.indexOf(state.appQuery.tag) > -1;
                });
            }
        }
        if (state.appQuery.department) {
            if (state.appQuery.department == '其他') {
                all_apps = all_apps.filter(item => item.tags == '' || item.tags == null);
            } else {
                all_apps = all_apps.filter((item) => {
                    return item.department && item.department.indexOf(state.appQuery.department) > -1;
                });
            }
        }

        let zimuS = all_apps.map(item => {
            return item.shouzimu;
        })
        state.zimu = Array.from(new Set(zimuS)).sort();
        state.tagApps = all_apps;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}