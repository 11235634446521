import {publicMenthods} from '@/mixins/utils';

var terminal = 'pc';
if (publicMenthods.getMobile()) terminal = 'wechat';

export default {
    path: '/',
    name: 'layout',
    redirect: '/index',
    component: () => import(`@/views/${terminal}/layout/index`),
    children: [
        {
            path: '/index',
            name: 'index',
            component: () => import(`@/views/${terminal}/index`),
            meta: {
                title: '工作台'
            }
        },
        {
            path: '/todoCenter',
            name: 'todoCenter',
            component: () => import(`@/views/${terminal}/todoCenter/index`),
            redirect: '/todoCenter/todo',
            children: [
                {
                    path: '/todoCenter/todo',
                    name: 'todo',
                    component: () => import(`@/views/${terminal}/todoCenter/todo`),
                    meta: {
                        title: '待办'
                    }
                },
                {
                    path: '/todoCenter/doing',
                    name: 'doing',
                    component: () => import(`@/views/${terminal}/todoCenter/doing`),
                    meta: {
                        title: '进行'
                    }
                },
                {
                    path: '/todoCenter/done',
                    name: 'done',
                    component: () => import(`@/views/${terminal}/todoCenter/done`),
                    meta: {
                        title: '完结'
                    }
                },
                {
                    path: '/todoCenter/cc',
                    name: 'cc',
                    component: () => import(`@/views/${terminal}/todoCenter/cc`),
                    meta: {
                        title: '抄送'
                    }
                }
            ]
        },
        {
            path: '/notification',
            name: 'notification',
            component: () => import(`@/views/${terminal}/notification/index`),
            redirect: '/notification/noread',
            children: [
                {
                    path: '/notification/read',
                    name: 'read',
                    component: () => import(`@/views/${terminal}/notification/read`),
                    meta: {
                        title: '已阅'
                    }
                },
                {
                    path: '/notification/noread',
                    name: 'noread',
                    component: () => import(`@/views/${terminal}/notification/noread`),
                    meta: {
                        title: '待阅'
                    }
                }
            ]
        },
        {
            path: '/schedule/index',
            name: 'schedule',
            component: () => import(`@/views/${terminal}/schedule/index`),
            meta: {
                title: '日程'
            }
        },
        {
            path: '/app/index',
            name: 'app',
            component: () => import(`@/views/${terminal}/app/index`),
            meta: {
                title: '应用'
            }
        },
        {
            path: '/notice/index',
            name: 'notice',
            component: () => import(`@/views/${terminal}/notice/index`),
            meta: {
                title: '公告'
            }
        },
        {
            path: '/notice/preview',
            name: 'noticePreview',
            component: () => import(`@/views/${terminal}/notice/preview`),
            meta: {
                title: '公告预览'
            }
        }
    ]
}
