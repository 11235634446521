import store from '..'
import axios from 'axios'
import gql from "@/graphql/todoCenter"

const state = {
    loadingDataStart: false,
    //apiMap里的keys需要和路由的名字保持一致
    apiMap: {
        'todo': {
            url: 'me/tasks/todo'
        },
        'doing': {
            url: 'me/processes/doing',
            query: {
                limit: 10,
                start: 0,
                keyword: '',
            },
        },
        'done': {
            url: 'me/processes/done',
            query: {
                limit: 10,
                start: 0,
                keyword: '',
            }
        },
        'cc': {
            url: 'me/processes/cc',
            query: {
                limit: 10,
                start: 0,
                // unread: true,
                keyword: '',
            },
        },
        'completed': {
            url: 'me/processes/completed',
            query: {
                limit: 10,
                start: 0,
                keyword: '',
            },
        }
    },
    dataSource: {
        'todo': [],
        'doing': [],
        'done': [],
        'cc': [],
        "completed": [],
    },
    dataLength: {
        'todo': 0,
        'done': 0,
        'doing': 0,
        'unread_cc': 0,   //未读的抄送数量
        'cc': 0,
        'completed': 0
    }
}
const getters = {
    getId: () => store.state.oidcStore.user.openId,
    getDataLength: () => state.dataLength,
    getDataSourceMap: () => state.apiMap
}

const actions = {
    //将参数原始化
    ClearQ({commit, state, dispatch}, {dataName}) {
        let origin_query = {
            limit: 10,
            start: 0,
            unread: true,
            keyword: '',
        };
        let origin = state.apiMap[dataName];
        if (origin && origin.query) {
            for (let key in origin_query) {
                if (origin.query.hasOwnProperty(key)) {
                    origin.query[key] = origin_query[key];
                }
            }
            state.apiMap[dataName].query = origin && origin.query;
        }
        dispatch('resetData', {dataName, 'query': origin && origin.query});
    },

    //调用接口的搜索
    SearchQ({dispatch, commit}, {key, property, val, limitRefresh}) {
        // limitRefresh阻止刷新
        let str1 = JSON.stringify(state.apiMap[key].query);
        state.apiMap[key].query[property] = val;
        let str2 = JSON.stringify(state.apiMap[key].query);
        if (property != 'start' && property != 'limit') {
            state.apiMap[key].query['start'] = 0;
        }
        let query = state.apiMap[key].query;
        let dataName = key;
        // 更改参数
        commit('ChangeQ', {dataName, query});
        if (str1 != str2 && !limitRefresh) {
            dispatch('resetData', {dataName, query});
        }
    },

    async resetData({commit, state, dispatch}, {dataName, query}) {
        //办事大厅的数据才触发重新resetData
        
        let url = "";
        let params = {};
        if(['todo', 'doing', 'done', 'cc', 'completed'].indexOf(dataName) != -1){
            commit('loadingData', true);
            // if(!dataName) dataName = 'todo';
            url = state.apiMap[dataName]?.url;
            // 合并参数
            params = Object.assign({}, query || state.apiMap[dataName].query);
            
            if (params.hasOwnProperty('unread') && !params.unread) {
                delete params.unread;
            }
            if (params.hasOwnProperty('keyword') && !params.keyword) {
                delete params.keyword;
            }
            if (params.hasOwnProperty('timestamps') && !params.timestamps) {
                delete params.timestamps;
            }
        }
        if (window.commonApi.hasOwnProperty('TASKAPI_URL') && window.commonApi.TASKAPI_URL.length > 1 && ['todo', 'doing', 'done', 'cc', 'completed'].indexOf(dataName) != -1) {
            // 参数调整
            let adjustParams = Object.assign({}, params);
            if (adjustParams.hasOwnProperty('start') && adjustParams.hasOwnProperty('limit')) {
                adjustParams.page = (adjustParams.start / adjustParams.limit);
                adjustParams.size = adjustParams.limit;
                delete adjustParams.limit;
                delete adjustParams.start;
            }
            let updateParams = {filter:{}};
            if (adjustParams.hasOwnProperty('keyword')){
                updateParams.filter.keyword = {};
                updateParams.filter.keyword.eq = adjustParams.keyword;
                delete adjustParams.keyword;
                adjustParams = {...adjustParams,...updateParams};
            }
            let nowParams = {
                ...adjustParams,
                cate: dataName
            }
            apolloProvider.clients.taskapi.query({
                query: gql[dataName],
                variables: nowParams,
                client: 'taskapi',
                fetchPolicy: "no-cache",
            }).then((res) => {
                let items = []
                let total = 0;
                switch (dataName) {
                    case 'todo':
                        items = res.data.tasks;
                        total = res.data.tasks.length;
                        break;
                    case 'doing':
                        items = res.data.processes.edges;
                        total = res.data.processes.totalCount;
                        break;
                    case 'done':
                        items = res.data.processes.edges;
                        total = res.data.processes.totalCount;
                        break;
                    case 'cc':
                        items = res.data.processes.edges;
                        total = res.data.processes.totalCount;
                        break;
                    case 'completed':
                        items = res.data.processes.edges;
                        total = res.data.processes.totalCount;
                        break;
                }
                commit('dataGet', {
                    items,
                    total,
                    dataName
                });
                commit('loadingData', false);
            }).catch(error => {
                console.error(error)
                commit('loadingData', false);
            });
        } else if(url) {
            await axios.get(`${window.commonApi.INFOPLUS_URL}/${url}`, {params}).then(res => {
                if (res.data.errno == 0) {
                    let items = res.data.entities;
                    let total = res.data.total;
                    commit('dataGet', {
                        items,
                        total,
                        dataName
                    });
                    commit('loadingData', false);
                }
            }).catch(function (error) {
                console.log(error);
                commit('loadingData', false);
            });
        }else{
            //
        }
    }
}

const mutations = {
    dataGet(state, {items, total, dataName}) {
        let nowItems = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i] && items[i].node) {
                nowItems.push(items[i].node);
            }
        }
        if (dataName == 'todo') {
            state.dataSource[dataName] = items;
        } else {
            state.dataSource[dataName] = nowItems;
        }
        state.dataLength[dataName] = total;
    },
    ChangeQ(state, {dataName, query}) {
        state.apiMap[dataName].query = Object.assign({}, query);
    },
    //骨架屏
    loadingData(state, load_bool) {
        state.loadingDataStart = load_bool;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}